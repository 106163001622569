<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header headerName="巴林右旗动物免疫疫苗统计" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_p h_full flex_column_between">
        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1">
          <div class="text_1 jian_bian_text_1 text_1 fw_bold pb_10">春季疫苗列表</div>

          <div class="w_full h_full-40 overflow_y_auto scroll_box">
            <div 
              class="w_full p_10 cursor text_1 fw_bold flex_start theme_color_green" 
              :class="{active_color: activeIndex == index}"
              v-for="(item, index) in springYiMiaoList" :key="index"
              @click="handleClickYiMiaoName(index)"
            >
              <span class="w_60 font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="w_60 font_number" v-else>{{ index+1 }}</span> 
                    
              <div class="w_full-100 cursor">
                {{ item.name }}
              </div> 
            </div>
          </div>
        </div>

        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1">
          <div class="text_1 jian_bian_text_1 text_1 fw_bold pb_10">秋季疫苗列表</div>

          <div class="w_full h_full-40 overflow_y_auto scroll_box">
            <div 
              class="w_full p_10 cursor text_1 fw_bold flex_start theme_color_orange" 
              :class="{active_color: activeIndex2 == index}"
              v-for="(item, index) in autumnYiMiaoList" :key="index"
              @click="handleClickYiMiaoName2(index)"
            >
              <span class="w_60 font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="w_60 font_number" v-else>{{ index+1 }}</span> 
                    
              <div class="w_full-100 cursor">
                {{ item.name }}
              </div> 
            </div>
          </div>
        </div>
      </div> 

      <!-- 2 -->
      <div class="w_32_p h_full flex_column_between">
        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1">
          <div class="text_1 jian_bian_text_1 text_1 fw_bold pb_10">疫苗图片</div>
          <div class="w_full h_full-40">
            <img class="full border_radius_10 response_img" :src="filePath + currentYiMiao.picture" alt="">
          </div>
        </div>

        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1">
          <div class="text_1 jian_bian_text_1 text_1 fw_bold pb_10">疫苗详情</div>
          <p class="color_fff text_indent_2">防疫动物类型: <span class="main_color">{{ currentYiMiao.effect }}</span></p>
          <p class="color_fff text_indent_2">描述: <span class="main_color">{{ currentYiMiao.description }}</span></p>
          <p class="color_fff text_indent_2">防疫动物类型: <span class="main_color">{{ currentYiMiao.specs }}</span></p>
        </div>
      </div> 

      <!-- 3 -->
      <div class="scroll_box w_32_p h_full overflow_y_auto p_10 border_box bgs_full chart_box_bgi_2">
        <div class="text_1 jian_bian_text_1 text_1 fw_bold">防疫提醒</div>
        <cattle-list :list="cattleList" @sendCattleId="acceptCattleId" />
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'

  import { filePath } from '@/const.js'

  import { getVaccinumTree } from '@/api/fang-yi/fang-yi.js'

  export default {
    name: 'YiMiaoPage',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin],
    data() {
      return {
        filePath: filePath,

        activeIndex: 0,
        activeIndex2: null,
        
        yiMiaoTree: [],
        springYiMiaoList: [],
        autumnYiMiaoList: [],

        currentYiMiao: {},
        cattleList: [],
      }
    },

    mounted() {
      this.getVaccinumTree()
    },

    methods: {
      // 获取疫苗树
      async getVaccinumTree() {
        let res = await getVaccinumTree()
        try {
          if (res.code == 1000) {
            this.yiMiaoTree = res.data

            for (let i in res.data) {
              if (res.data[i].name == '春季') {
                this.springYiMiaoList = res.data[i].children
              } else if (res.data[i].name == '秋季') {
                this.autumnYiMiaoList = res.data[i].children
              } 
            }

            this.currentYiMiao = this.springYiMiaoList[0]
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 点击 疫苗 item
      handleClickYiMiaoName(index) {
        this.activeIndex = index
        this.currentYiMiao = this.springYiMiaoList[index]

        console.log('this.curentYiMiao : ', this.currentYiMiao);
      },

      // 点击 疫苗 item
      handleClickYiMiaoName2(index) {
        this.activeIndex2 = index
        this.currentYiMiao = this.autumnYiMiaoList[index]
      },

      // 接受 cattle_id
      acceptCattleId(cattle_id) {
        // 跳转到 牛详情 页面
        this.$router.push({
          path: "/cattleDetail/" + cattle_id
        })
      }

    }
  }
</script>